import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FabricBaseIconComponent } from './fabric-base-icon.component';
import { FabricBaseIconService } from './fabric-base-icon.service';

@NgModule({
  declarations: [FabricBaseIconComponent],
  imports: [CommonModule],
  exports: [FabricBaseIconComponent]
})
export class FabricBaseIconModule {
  static forRoot(): ModuleWithProviders<FabricBaseIconModule> {
    return {
      ngModule: FabricBaseIconModule,
      providers: [FabricBaseIconService]
    };
  }
}
